.row-info {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  height: 36px;
}

.info-bg {
  background-color: #cecece52;
  border-radius: 2px;
}

.row-info>div {
  width: 50%;
}