.item-menu {
  font-size: 24px;
  margin-left: 8px;
}

.menu-more-actions {
  color: #fff !important;
}


#simple-menu a {
  color: unset;
  text-decoration: none;
}

.bg-sidebar {
  width: 100%;
  height: 100vh;
  background: #091839;
  background: -webkit-gradient(linear, left top, left bottom, from(#091839), to(#12578f));
  background: linear-gradient(180deg, #091839, #12578f);
  -webkit-box-shadow: 0 16px 38px -12px rgba(0, 0, 0, .56), 0 4px 25px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2);
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, .56), 0 4px 25px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2);
  background-size: cover;
  color: #fff;
}

.MuiListItemText-primary {
  color: #fff !important;
}

.MuiListItemText-secondary {
  color: #cecece !important;
  font-size: 11px !important;
}

.white {
  color: #fff !important;
}

.bg-white {
  background: rgba(255, 255, 255, 0.12) !important
}

.shadow-sidebar {
  -webkit-box-shadow: 0 16px 38px -12px rgba(0, 0, 0, .56), 0 4px 25px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2);
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, .56), 0 4px 25px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2);
}

#user-info>.MuiListItemText-primary {
  font-size: 12px;
}

.makeStyles-drawerPaperClose-8 {
  width: 66px !important;
}

.bg-selected {
  background-color: rgb(0, 188, 212) !important;
  box-shadow: rgba(0, 188, 212, 0.28) 0px 12px 20px -10px, rgba(0, 0, 0, 0.12) 0px 4px 20px 0px, rgba(0, 188, 212, 0.2) 0px 7px 8px -5px;
}

.MuiDrawer-paperAnchorDockedLeft {
  border: none !important;
}