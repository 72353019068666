.row-info {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  height: 36px;
}

.info-bg {
  background-color: #cecece52;
  border-radius: 2px;
}

.row-info>div {
  width: 50%;
}

.MuiBadge-badge {
  height: 14px !important;
  width: 28px !important;
}

.MuiCalendarPicker-root {
  z-index: 999999999999999999999999999999 !important;
}