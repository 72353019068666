/* importando fonte popins */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
/* definindo fonte padrão nos textos */

body, h1, h2, h3, h4, p, a, b, label, input {
    font-family: "Poppins", sans-serif;
}

.font-default {
    font-family: "Poppins", sans-serif;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif !important;
}

body {
    font: 400 14px 'Poppins', sans-serif !important;
    -webkit-font-smoothing: antialiased !important;
}

input, button, textarea {
    font: 400 18px 'Poppins', sans-serif;
}

button {
    cursor: pointer;
}

.logo-color {
    background: #864dff !important;
}

a {
    text-decoration: none !important;
    color: unset !important;
    display: flex;
}

.drawer-bottom {
    position: absolute;
    bottom: 10px;
    right: 15px;
    display: flex;
    flex-direction: row;
}

.drawer-bottom button {
    min-width: 130px;
}

.drawer-bottom button+button {
    margin-left: 10px;
}

.input-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.input-box2 {
    width: 47%;
}

.input-box3 {
    width: 31%;
}

.form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;
  }

  
/* 
.input-group>.MuiTextField-root {
    width: 48% !important;
}

.input-group>.MuiInput-root {
    width: 48% !important;
} */

.title {
    font-size: 38px;
    font-weight: 700;
    text-align: center;
}

.MuiDialog-paperFullWidth {
    z-index: 999999999999999999999;
}