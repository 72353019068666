@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap);
.item-menu {
  font-size: 24px;
  margin-left: 8px;
}

.menu-more-actions {
  color: #fff !important;
}


#simple-menu a {
  color: unset;
  text-decoration: none;
}

.bg-sidebar {
  width: 100%;
  height: 100vh;
  background: #091839;
  background: linear-gradient(180deg, #091839, #12578f);
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, .56), 0 4px 25px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2);
  background-size: cover;
  color: #fff;
}

.MuiListItemText-primary {
  color: #fff !important;
}

.MuiListItemText-secondary {
  color: #cecece !important;
  font-size: 11px !important;
}

.white {
  color: #fff !important;
}

.bg-white {
  background: rgba(255, 255, 255, 0.12) !important
}

.shadow-sidebar {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, .56), 0 4px 25px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2);
}

#user-info>.MuiListItemText-primary {
  font-size: 12px;
}

.makeStyles-drawerPaperClose-8 {
  width: 66px !important;
}

.bg-selected {
  background-color: rgb(0, 188, 212) !important;
  box-shadow: rgba(0, 188, 212, 0.28) 0px 12px 20px -10px, rgba(0, 0, 0, 0.12) 0px 4px 20px 0px, rgba(0, 188, 212, 0.2) 0px 7px 8px -5px;
}

.MuiDrawer-paperAnchorDockedLeft {
  border: none !important;
}
.rdt_TableHeader {
  display: none !important;
}
.shadow {
  box-shadow: 1px 1px 4px grey;
}


.MuiDialog-paperWidthSm {
  max-width: 100% !important;
}
/* .row-info {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  height: 36px;
}

.info-bg {
  background-color: #cecece52;
  border-radius: 2px;
}

.row-info>div {
  width: 50%;
}

.MuiBadge-badge {
  height: 14px !important;
  width: 28px !important;
} */

.container {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 18px;
}
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100% !important;
}
.row-info {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  height: 36px;
}

.info-bg {
  background-color: #cecece52;
  border-radius: 2px;
}

.row-info>div {
  width: 50%;
}

.MuiBadge-badge {
  height: 14px !important;
  width: 28px !important;
}
.row-info {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  height: 36px;
}

.info-bg {
  background-color: #cecece52;
  border-radius: 2px;
}

.row-info>div {
  width: 50%;
}

.MuiBadge-badge {
  height: 14px !important;
  width: 28px !important;
}

.MuiCalendarPicker-root {
  z-index: 999999999999999999999999999999 !important;
}
.row-info {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  height: 36px;
}

.info-bg {
  background-color: #cecece52;
  border-radius: 2px;
}

.row-info>div {
  width: 50%;
}
.row-info {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  height: 36px;
}

.info-bg {
  background-color: #cecece52;
  border-radius: 2px;
}

.row-info>div {
  width: 50%;
}
.row-info {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  height: 36px;
}

.info-bg {
  background-color: #cecece52;
  border-radius: 2px;
}

.row-info>div {
  width: 50%;
}
/* importando fonte popins */
/* definindo fonte padrão nos textos */

body, h1, h2, h3, h4, p, a, b, label, input {
    font-family: "Poppins", sans-serif;
}

.font-default {
    font-family: "Poppins", sans-serif;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif !important;
}

body {
    font: 400 14px 'Poppins', sans-serif !important;
    -webkit-font-smoothing: antialiased !important;
}

input, button, textarea {
    font: 400 18px 'Poppins', sans-serif;
}

button {
    cursor: pointer;
}

.logo-color {
    background: #864dff !important;
}

a {
    text-decoration: none !important;
    color: unset !important;
    display: flex;
}

.drawer-bottom {
    position: absolute;
    bottom: 10px;
    right: 15px;
    display: flex;
    flex-direction: row;
}

.drawer-bottom button {
    min-width: 130px;
}

.drawer-bottom button+button {
    margin-left: 10px;
}

.input-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.input-box2 {
    width: 47%;
}

.input-box3 {
    width: 31%;
}

.form-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;
  }

  
/* 
.input-group>.MuiTextField-root {
    width: 48% !important;
}

.input-group>.MuiInput-root {
    width: 48% !important;
} */

.title {
    font-size: 38px;
    font-weight: 700;
    text-align: center;
}

.MuiDialog-paperFullWidth {
    z-index: 999999999999999999999;
}
